import Styled from 'styled-components';

export const TopContainer = Styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  z-index: 0;
  padding-top: 100px;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const MottoContainer = Styled.div`
  display: block;
`;

export const MottoBackground = Styled.div`
  display: block;
`;

export const Motto = Styled.h1`
  display: block;
  position: relative;
  font-family: 'Helvetica Neue Roman', sans-serif;
  font-size: 30px;
  color: #494956;
  text-align: left;
  margin-top: 20px;
  padding: 0;
  letter-spacing: 0.5px;

  @media (max-width: 700px) {
    font-size: 28px;
    text-align: center;
    letter-spacing: -.25px;
    margin: 0;
    overflow: hidden;
  }
`;

export const Submotto = Styled(Motto)`
  font-family: 'Helvetica Neue Thin', sans-serif;
  font-size: 18px;
  margin-top: -22px;
  line-height: 24px;
  color: #666;
  letter-spacing: 0.5px;

  @media (max-width: 700px) {
    font-family: 'Helvetica Neue Light', sans-serif;
    font-size: 16px;
    text-align: center;
    letter-spacing: -.25px;
    line-height: 18px;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
`;

export const TitleContainer = Styled.div`
  display: block;
  position: relative;
  margin: 0 auto;
  width: 100%;
  clear: both;
  padding-top: 10px;

  @media (max-width: 700px) {
    user-select: none;
    overflow: hidden;
  }
`;

export const IconContainer = Styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 80px;
    
  @media (max-width: 700px) {
    padding-top: 40px;
  }
`;

export const IconSub = Styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 25%;
  background-color: #fff;
  height: 100px;

  @media (max-width: 900px) {
    width: 50% !important;
  }
`;

export const Icon = Styled.img`
  display: block;
  width: 60px;
  height: 60px;
`;

export const SubBasket = Styled.div`
  display: block;
  width: 45%;
`;

export const BasketContainer = Styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  padding-top: 180px;
`;

export const LargeImg = Styled.img`
  display: block;
  width: 100%;
`;

export const IconTitle = Styled.h3`
  display: block;
  font-family: 'Helvetica Neue Roman', sans-serif;
  font-size: 12px;
  color: #494956;
  text-align: center;
  width: 70%;
  margin: 0 auto;
  letter-spacing: .75px;
`;

export const Title = Styled.h1`
  display: block;
  font-family: 'Helvetica Neue Roman', sans-serif;
  font-size: 26px;
  color: #494956;
  text-align: center;
  width: 50%;
  margin: 0 auto;
  letter-spacing: .75px;

  @media (max-width: 700px) {
    width: 80%;
    user-select: none;
    letter-spacing: -2px;
    overflow: hidden;
  }
`;

export const BasketTitle = Styled(Title)`
  width: 100%;
  text-align: left;
`;

export const Subtitle = Styled(BasketTitle)`
  font-size: 16px;
  padding-top: 35px;
  font-family: 'Helvetica Neue Light', sans-serif;
  letter-spacing: .5px;
  color: #494956;
  line-height: 19px;
  width: 100%;
  text-align: left;

  @media (max-width: 700px) {
    width: 80%;
    user-select: none;
    letter-spacing: -2px;
    overflow: hidden;
  }
`;

export const ContactText = Styled(Subtitle)`
  color: #BBBABA;
  white-space: nowrap;
  text-align: center;
  width: auto;
  padding-top: 10px;
`;

export const GlobeImg = Styled.img`
  display: block;
  width: 60%;
`;

export const SpecContainer = Styled.div`
  display: block;
  width: 100%;
  padding-top: 120px;
  padding-bottom: 40px;
`;

export const SpecSection = Styled.div`
  border-top: 1px solid #eee;
  display: table;
  width: 95%;
  margin: 0 auto;
  padding-bottom: 40px;
`;

export const SpecSuperTitle = Styled(Title)`
  padding-bottom: 20px;
`;

export const SpecTitle = Styled(Title)`
  display: block;
  width: 100%;
  text-align: left;
  font-size: 18px;
  float: left;
`;

export const Spec = Styled(Subtitle)`
  width: 100%;
  text-align: left;
  float: left;
  padding: 0 0 20px 0;
  margin: 0;
`;

export const ThinSpec = Styled(Spec)`
  width: 30%;
`;

export const SpecTitleSection = Styled.div`
  display: block;
  width: 30%;
  float: left;
  padding-top: 40px;
  padding-left: 5px;
`;

export const SubspecsSection = Styled.div`
  display: block;
  width: 60%;
  float: left;
  padding-top: 40px;
  padding-left: 5px;
`;

export const ThinSubspecsSection = Styled(SubspecsSection)`
  width: 30%;
`;

export const Copyright = Styled(ContactText)`
  padding-top: 70px;
  font-size: 13px;
  @media (max-width: 700px) {
    padding-bottom: 10px;
  }
  @media (max-width: 700px) {
    padding-top: 30px;
  }
`;