import React from "react";
import { Link } from "gatsby";
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from "react-helmet";

import Layout from "../components/layout.tsx";
import Image from "../components/image.tsx";
import ProductImage from "../components/productImage.tsx";
import ArrowIcon from "../images/arrow-icon.svg";
import CancelIcon from "../images/close.svg";
import { ThreeDimensionalModel } from "../components/threeDimensionalModel.tsx";
import HapsGlobe from "../images/Haps-Globe.svg";
import FiveGIcon from "../images/5g-icon.svg";
import lteIcon from "../images/lte-icon.svg";
import usFlagIcon from "../images/us-flag-icon.svg";
import absideCircleIcon from "../images/abside-circle-icon.svg";
import loonBasketImage from "../images/Project-Loon-Google.png";

import { 
  TopContainer,
  MottoContainer,
  MottoBackground,
  Motto,
  Submotto,
  TitleContainer,
  IconContainer,
  IconSub,
  Icon,
  IconTitle,
  Title,
  BasketTitle,
  Subtitle,
  ContactText,
  GlobeImg,
  BasketContainer,
  SubBasket,
  LargeImg,
  SpecContainer,
  SpecSection,
  SpecTitle,
  Spec,
  ThinSpec,
  SpecTitleSection,
  SubspecsSection,
  SpecSuperTitle,
  ThinSubspecsSection,
  Copyright
} from "../styles/HapsStyles.ts";

const HAPS = () => {
  const [show3DModel, setShow3DModel] = React.useState([false, false, false]);

  const handleToggle = (index) => {
    let array = show3DModel;
    array[index] = !array[index];
    setShow3DModel([...array]);
  }
  
  return (
    <div>
      <Layout>
        <Helmet>
          <title>HAPS - Abside Networks</title>
        </Helmet>
        <TopContainer>
          <MottoContainer>
            <MottoBackground>
              <Motto>High Altitude Platform Solution</Motto>
              <Submotto>Abside’s innovative networking hardware enables <br />HAPS to transmit 4G LTE & 5G.</Submotto>
            </MottoBackground>
          </MottoContainer>
          <GlobeImg src={HapsGlobe} />
        </TopContainer>
        <TitleContainer>
          <Title>At a Glance</Title>
        </TitleContainer>
        <IconContainer>
          <IconSub>
            <Icon src={lteIcon} />
            <IconTitle>BYOD - Proven Compatibility with 4G COTS Modems</IconTitle>
          </IconSub>
          <IconSub>
            <Icon src={FiveGIcon} />
            <IconTitle>5G *In Development</IconTitle>
          </IconSub>
          <IconSub>
            <Icon src={usFlagIcon} />
            <IconTitle>4 Simultaneous Active Cells @ 2T2R Integrated antenna for maximal TX and RX performance</IconTitle>
          </IconSub>
          <IconSub>
            <Icon src={absideCircleIcon} />
            <IconTitle>Over 500,000 Users Connected Over 24 TB User Data Served Proven air-to-ground communications</IconTitle>
          </IconSub>
        </IconContainer>
        <BasketContainer>
          <SubBasket>
            <BasketTitle>The Basket.</BasketTitle>
            <Subtitle>The Abside Networks High Altitude Platform Solution (HAPS) is based on 7+ years, 3+ generations of development, and is a proven solution with over 700 units produced and fielded. Our solution has served over 500,000 users in cooperation with Google Loon.<br /><br />The Abside HAPS is a complete LTE eNodeB including both the RRH and BBU and the antenna unit in a single unit called a basket.</Subtitle>
          </SubBasket>
          <SubBasket>
            <LargeImg src={loonBasketImage} />
          </SubBasket>
        </BasketContainer>
        <SpecContainer>
          <SpecSuperTitle>Tech Specs</SpecSuperTitle>
          <SpecSection>
            <SpecTitleSection>
              <SpecTitle>4G LTE</SpecTitle>
            </SpecTitleSection>
            <ThinSubspecsSection>
              <Spec>LTE Band 20</Spec>
              <Spec>LTE Band 28</Spec>
            </ThinSubspecsSection>
            <ThinSubspecsSection>
              <Spec>Up to 10 MHz Channel Bandwidth</Spec>
            </ThinSubspecsSection>
          </SpecSection>
          <SpecSection>
            <SpecTitleSection>
              <SpecTitle>5G NR</SpecTitle>
            </SpecTitleSection>
            <SubspecsSection>
              <Spec>5G NR Band n20</Spec>
              <Spec>5G NR Band n28</Spec>
              <Spec>*In Development</Spec>
            </SubspecsSection>
          </SpecSection>
          <SpecSection>
            <SpecTitleSection>
              <SpecTitle>RF Solution</SpecTitle>
            </SpecTitleSection>
            <SubspecsSection>
              <Spec>2 Sectors per basket, 2 Baskets per balloon (pictured)</Spec>
              <Spec>Each Sector is 2T2R MIMO with Integrated Dual Slant Polarization Antenna.</Spec>
            </SubspecsSection>
          </SpecSection>
          <SpecSection>
            <SpecTitleSection>
              <SpecTitle>TX Power</SpecTitle>
            </SpecTitleSection>
            <SubspecsSection>
              <Spec>Each Sector is 2 x +5W (+37 dBm per Transmitter)</Spec>
              <Spec>Basket total of 4 x +5W</Spec>
            </SubspecsSection>
          </SpecSection>
          <SpecSection>
            <SpecTitleSection>
              <SpecTitle>4G LTE Range</SpecTitle>
            </SpecTitleSection>
            <SubspecsSection>
              <Spec>Proven 4G LTE connectivity and data transfer to COTS UE (phone) at 45+ miles</Spec>
            </SubspecsSection>
          </SpecSection>
          <SpecSection>
            <SpecTitleSection>
              <SpecTitle>5G NR Range</SpecTitle>
            </SpecTitleSection>
            <SubspecsSection>
              <Spec>*5G NR Range Test Pending</Spec>
            </SubspecsSection>
          </SpecSection>
          <SpecSection>
            <SpecTitleSection>
              <SpecTitle>Temp Range</SpecTitle>
            </SpecTitleSection>
            <SubspecsSection>
              <Spec>-40 to +70 °C</Spec>
            </SubspecsSection>
          </SpecSection>
          <SpecSection>
            <SpecTitleSection>
              <SpecTitle>Weight</SpecTitle>
            </SpecTitleSection>
            <SubspecsSection>
              <Spec>7.5 kg</Spec>
            </SubspecsSection>
          </SpecSection>
        </SpecContainer>
        <Copyright>Copyright © {new Date().getFullYear()} Abside Networks, Inc.</Copyright>
      </Layout>
    </div>
  );
}

export default HAPS;
